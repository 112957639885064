import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import { useSnackbar } from "notistack";
import "./userOrderDetailsForm.css";
import { useNavigate } from "react-router-dom";
import { useLoginFormValidator } from "./hooks/useRegisterForm";

import CountryCodeData from "../../../Helpers/CountryCodeData";
import { Box } from "@mui/system";
import { Theme, useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import FontHelper from "../../../Helpers/FontHelper";
import { useEffect } from "react";
import axios from "axios";
import { ApiManager } from "../../../ApiClient";
import {
  nameValidator,
  mobileNoValidator,
  emailValidator,
  genderValidator,
  shippingAddressValidator,
  pinValidator,
} from "./Validator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};

const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email"),
  phone_number: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
  pinCode: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .required("Required!"),
});

const UserOrderDetailsForm = ({ price, printInstruction }) => {


  const navigate = useNavigate();
  const snack = useSnackbar();

  const form = useRef();
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const selectedValue = useSelector((state) => state.shop.packageDetails);
  const [isVerifiedName, setVerifiedName] = useState(false);
  const [isVerifiedEmail, setVerifiedEmail] = useState(false);
  const [isVerifiedNumber, setVerifiedNumber] = useState(false);
  const [isVerifiedGender, setVerifiedGender] = useState(false);
  const [isVerifiedShoppingAddress, setVerifiedShoppingAddress] =
    useState(false);
  const [isVerifiedPinCode, setVerifiedPinCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const productInfo = useSelector((state) => state.shop.products);
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const Orientation = localStorage.getItem("Orientation");
  const digitalDownload = cartInfo.filter((item) => item.type === "download" && item.category === "image")
  const printsCart = cartInfo.filter((item) => item.type == "print");
  const collageCart = cartInfo.reduce((items, item) => {
    if (item.type.includes("collage")) {
      items.push({ ...item, type: "collage" + Orientation });
    }
    return items;
  }, [])
  const cartVideo = cartInfo.filter((item) => {
    return item.category === "video";
  });
  const { appliedDiscount, discountedPhoto } = useSelector(
    (state) => state.shop.sponsorDiscount
  );
  const userIsdCode = useSelector((state) => state.user.isdCode);

  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const user = useSelector((state) => state.user.user);
  const isSignedIn = localStorage.getItem("isSignedIn");
  const [filteredCountryCode, setFilteredCountrycode] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [userDetails, setUserDetails] = React.useState(
    isSignedIn
      ? {
        name: user.firstName,
        email: user.email,
        phone_number: user.primaryPhone,
        gender: user.gender,
        shipping_address: user.address,
        pincode: user.pincode,
        countryCode: "+91"
      }
      : {
        name: "",
        email: "",
        phone_number: "",
        gender: "",
        shipping_address: "",
        pincode: "",
        countryCode: "+91"
      }
  );
  const { errors, validateForm, onBlurField } = useLoginFormValidator(userDetails);
  useEffect(() => {
    setUserDetails({ ...userDetails, countryCode: userIsdCode })
    // const userId = localStorage.getItem("ID");
    // if (userId) {
    //   axios
    //     .post(ApiManager.checkoutUserInfo(), {
    //       userID: userId
    //     })
    //     .then((response) => {
    //       if (response.data.StatusCode === 200) {
    //         setCheckoutInfo(response.data.result);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Error", err);
    //     });
    // }
  }, [])
  const onSubmit = (values, formicHelpers) => {
    // formicHelpers.resetForm();
  };


  const formlabels = {
    marginBottom: "-0.8rem",
    fontFamily: FontHelper.fBold,
    fontstyle: "normal",
    fontsize: "12px",
    lineheight: "18px",
    color: ColorHelper.PrimaryColor,
    flex: "none",
    order: 0,
    textAlign: "left",
    alignself: "stretch",
    flexgrow: 0,
    mt: 3,
  };

  const Gender = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];

  const handlemOpen = (e) => {
    e.preventDefault();
    setEvents(e.target.value);
  };

  const [events, setEvents] = useState("");

  const checkFormDetails = async () => {
    setIsLoading(true);
    const Orientation = localStorage.getItem("Orientation");

    let updatedCart = [];
    if (selectedValue === "a" && digitalDownload.length >= productInfo.threshold_photo_count) {
      updatedCart = [
        {
          id: "b_premium",
          image: productInfo.photo.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          price: productInfo.bundlePriceInfo.Premium_Pack,
        },
        ...cartVideo,
        ...printsCart,
        ...collageCart
      ];
    } else if (selectedValue === "b" && digitalDownload.length >= productInfo.threshold_photo_count) {
      updatedCart = [
        {
          id: "b_ultraprem",
          image: productInfo.photo.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          price: productInfo.ultra_premium_pack,
        },
        ...cartVideo,
        ...printsCart,
        ...collageCart
      ];
    } else {
      updatedCart = cartInfo.map((item) => {
        if (item.type === "collage") {
          return { ...item, type: "collage" + Orientation };
        }
        return item;
      });
    }

    var createOrder = {
      newUserFlg: "1",
      userID: checkoutInfo.id ? checkoutInfo.id : "",
      name: userDetails.name,
      email: userDetails.email,
      phone_number: userDetails.phone_number,
      gender: userDetails.gender,
      shipping_address: userDetails.shipping_address,
      pincode: userDetails.pincode,
      isdCode: userDetails.countryCode,
      print_instruction: printInstruction,
      eventID: cartPrice.eventId,
      bibNumber: cartPrice.bibNumber,
      amount: cartPrice.total_amount,
      photoID: discountedPhoto.length !== 0 ? discountedPhoto : "",
      sponsorofferID: discountedPhoto.length !== 0 ? cartPrice.getSponsorOffersDetails.id : "",
      // "paymentID":"HM202212346",
      cartInfo: updatedCart,
    };
    // console.log("create order input :", { createOrder });

    if (
      (userDetails.name && isVerifiedName === true) &&
      (userDetails.email && isVerifiedEmail === true) &&
      (userDetails.phone_number && isVerifiedNumber === true) &&
      (userDetails.gender && isVerifiedGender === true) &&
      (userDetails.shipping_address && isVerifiedShoppingAddress === true) &&
      (userDetails.pincode && isVerifiedPinCode === true)
    ) {
      const response = await axios.post(ApiManager.createOrder(), {
        ...createOrder,
      });

      if (response.data.StatusCode == 200) {
        if (response.data.result.amount === 0) {
          localStorage?.setItem("txnid", response.data.result.txnid);
          setIsLoading(false);
          navigate("/ordersuccess");
        } else {
          var paymentPayload = {
            amount: response.data.result.amount,
            firstname: response.data.result.name,
            email: response.data.result.email,
            phone: response.data.result.phone,
            productinfo: response.data.result.productinfo,
            txnid: response.data.result.txnid,
            surl: ApiManager.orderSuccess(),
            furl: ApiManager.fURL(),
            hash: response.data.result.hash,
            key: response.data.result.key,
            paymentUrl: response.data.result.PAYU_API_URL,
          };
          setIsLoading(false);
          navigate("/order-confirmation", { state: paymentPayload });
        }
      } else {
        snack.enqueueSnackbar({
          variant: "error",
          message: response.data.Message,
        });
      }
    } else {
      setVerifiedName(nameValidator(userDetails.name))
      setVerifiedNumber(mobileNoValidator(userDetails.phone_number))
      setVerifiedEmail(emailValidator(userDetails.email))
      setVerifiedGender(genderValidator(userDetails.gender))
      setVerifiedShoppingAddress(shippingAddressValidator(userDetails.shipping_address))
      setVerifiedPinCode(pinValidator(userDetails.pincode))
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setEvents(event.target.value);
  };

  const toggleDropDown = () => {
    setOpenDropDown(true);
  }
  const handleFilter = (e) => {
    setUserDetails({
      ...userDetails,
      countryCode: e.target.value
    })

    const result = CountryCodeData.filter((ele) => (ele.code.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setFilteredCountrycode(result);
  }
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropDown(false);
    }
  };
  const handleSelectIsdCode = (code) => {
    setUserDetails({
      ...userDetails,
      countryCode: code
    })
    setOpenDropDown(false);
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Formik
      initialValues={userDetails}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, values, handleChange, handleBlur }) => {
        return (
          <Form ref={form}>
            <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
              <div className="modal-content">
                <div className="loader"></div>
                <div className="modal-text">Processing...</div>
              </div>
            </div>
            <CardContent>
              <Grid item container spacing={1} justify="center" sx={{ mt: -5 }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={formlabels}>Name</Typography>
                  <Typography sx={{ mt: 2 }}>
                    <Field
                      id="Name"
                      variant="outlined"
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          name: e.target.value,
                        });
                        setVerifiedName(nameValidator(e.target.value));
                      }}
                      fullWidth
                      name="Name"
                      autoComplete="Name"
                      value={userDetails.name}
                      component={TextField}
                      size="small"
                      placeholder="Name"
                    />
                  </Typography>

                  {isVerifiedName !== true && (
                    <Typography sx={{ color: "#e11d48" }}>
                      {isVerifiedName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={formlabels}>Email Address</Typography>
                  <Typography sx={{ mt: 2 }}>
                    <Field

                      id="email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      autoComplete="email"
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          email: e.target.value,
                        });

                        setVerifiedEmail(emailValidator(e.target.value));
                      }}
                      value={userDetails.email}
                      component={TextField}
                      size="small"
                      placeholder="Email Address"
                    />
                  </Typography>
                  {isVerifiedEmail !== true && (
                    <Typography sx={{ color: "#e11d48" }}>
                      {isVerifiedEmail}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} >
                  <Typography sx={formlabels}>Mobile Number</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                  >
                    <Grid item xs={3} sm={3} md={3}>
                      <div class="dropdown">
                        <input
                          className="inputField"
                          name="isd_code"
                          variant="outlined"
                          fullWidth
                          value={userDetails.countryCode}
                          onBlur={onBlurField}
                          onClick={() => toggleDropDown()}
                          onChange={(e) => handleFilter(e)}
                          placeholder="ISD"
                          style={{
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                            padding: "10px"
                          }}
                        />
                        {openDropDown &&
                          <div class="dropdown-content">
                            {
                              filteredCountryCode.length === 0 ?
                                CountryCodeData.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                                :
                                filteredCountryCode.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                            }
                          </div>}
                      </div>
                    </Grid>

                    <Grid item xs={9} sm={9} md={9} sx={{ marginLeft: "7px" }}>
                      <Typography
                        sx={{ mt: 2, maxWidth: "100%" }}
                        className="tasksInput"
                      >
                        <input
                          variant="outlined"
                          fullWidth
                          name="phone_number"
                          style={{
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              phone_number: e.target.value,
                            });
                            setVerifiedNumber(
                              mobileNoValidator(e.target.value)
                            );
                          }}
                          // onBlur={onBlurField}
                          value={userDetails.phone_number}
                          className="inputField"
                          type="PhoneNo"
                          size="small"
                          placeholder="Mobile Number"
                        />
                      </Typography>
                      {isVerifiedNumber !== true && (
                        <Typography sx={{ color: "#e11d48" }}>
                          {isVerifiedNumber}
                        </Typography>
                      )}
                    </Grid>

                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={formlabels}>Gender</Typography>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={userDetails.gender}
                    name="gender"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        gender: e.target.value,
                      });
                      setVerifiedGender(genderValidator(e.target.value));
                    }}
                    // onBlur={onBlurField}
                    sx={{ width: "100%", mt: 2 }}
                    size="small"
                    className="inputField"
                    placeholder="Gender"
                  >
                    {Gender.map((item) => (
                      <MenuItem value={item.name} key={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.gender.dirty && errors.gender.error ? (
              <p className="formFieldErrorMessage">{errors.gender.message}</p>
            ) : null} */}
                  {isVerifiedGender !== true && (
                    <Typography sx={{ color: "#e11d48" }}>
                      {isVerifiedGender}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Typography sx={formlabels}>Shipping Address</Typography>
                  <Typography sx={{ mt: 2 }}>
                    {" "}
                    <Field
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          shipping_address: e.target.value,
                        });
                        setVerifiedShoppingAddress(
                          shippingAddressValidator(e.target.value)
                        );
                      }}
                      name="ShippingAddress"
                      placeholder="City name is sufficient if you are purchasing only digital images"
                      value={userDetails.shipping_address}
                      type="ShippingAddress"
                      component={TextField}
                      multiline={true}
                      size="small"
                      rows={3}
                    />
                  </Typography>

                  {isVerifiedShoppingAddress !== true && (
                    <Typography sx={{ color: "#e11d48" }}>
                      {isVerifiedShoppingAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={10} sm={10} md={10}>
                  <Typography sx={formlabels}>Pin Code</Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Field
                      variant="outlined"
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          pincode: e.target.value,
                        });
                        setVerifiedPinCode(pinValidator(e.target.value));
                      }}
                      // fullWidth
                      name="PinCode"
                      placeholder="Pin Code"
                      value={userDetails.pincode}
                      type="text"
                      component={TextField}
                      size="small"
                    />
                  </Typography>

                  {isVerifiedPinCode !== true && (
                    <Typography sx={{ color: "#e11d48" }}>
                      {isVerifiedPinCode}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <FilledButton
                handleClick={checkFormDetails}
                type="submit"
                value="submit"
                btnText={ConstantHelper.submitBtn}
                color={ColorHelper.WhiteColor}
                bgColor={ColorHelper.PrimaryColor}
                sx={{
                  ml: "0px",
                  width: { xs: "100%", lg: "auto" },
                }}
              />
            </CardActions>
          </Form>
        );
      }}
    </Formik>
  );
};
export default UserOrderDetailsForm;
