import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImageHelper from "../../../Helpers/ImageHelper";
import FontHelper from "../../../Helpers/FontHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";
import Carousel from "react-multi-carousel";
import UltraPremiumContent from "./ContentComponent/UltraPremiumContent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", lg: "825px" },
  height: { lg: "500px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: { xs: 2, md: "24px" },
  gap: "24px",
  borderRadius: "5px",
  flex: "none",
  order: "0",
  alignSelf: "stretch",
  flexGrow: "0",
};

const UltraPremiumPackageModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1619 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    laptop: {
      breakpoint: { max: 1619, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      arrows: true,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      slidesToSlide: 1,
      arrows: false,
    },
  };

  const carouselParams = {
    additionalTransfrom: 0,
    autoPlay: false,
    autoPlaySpeed: 2000,
    centerMode: false,
    className: "",
    containerClass: "carousel-container",
    // customButtonGroup: <SliderCustomBtns />,
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    customTransition: "all",
    transitionDuration: 100,
    renderButtonGroupOutside: true,
    renderDotsOutside: false,
    responsive: responsive,
    showDots: false,
    sliderClass: "",
    //   partialVisible: true
    // slidesToSlide: 1,
  };

  const modalSliderData = [
    {
      title: "Timed Image",
      discription1:
        "How about having the run time printed on the image? Your Image with race time may appear like this.",
      discription2:
        "These images will be available for limited runs where Organiser has signed an agreement with Oneglint. And, the time printed on the image will be provisional time available with Oneglint at the time of going live with Digital downloads.",
      image: ImageHelper.TimedImage,
    },
    {
      title: "Certificate Image",
      discription1:
        "Isn't it always exciting to have your photo on Participation Certificate? The participation certification with your photo (in action) will look like this and you get all selected photos in this format.",
      discription2:
        "The availability of Certificate images dependent on the run Organiser and Oneglint's agreement.",
      image: ImageHelper.CertificateImage,
    },
  ];
  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{ marginLeft: "-9px", textTransform: "none" }}
      >
        Click Here
      </Button>
      <span>to view the details</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            gap: "8px",
            borderRadius: "10px",
            flex: "none",
            order: "0",
            flexGrow: "0",
          }}
        >
          <Box sx={style} className="premiumModal">
            <Box
              sx={{
                borderBottom: "1px solid #E4E7EC",
                pb: "16px",
              }}
            >
              <Box
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  padding: { xs: 0, md: "0px 0px 8px 16px" },
                  gap: { xs: "6px", md: "282px" },
                  flex: "none",
                  order: "0",
                  alignSelf: "stretch",
                  flexGrow: "0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px",
                    width: { xs: "90%", md: "90%" },
                    gap: "8px",
                    flex: "none",
                    order: "0",
                    flexGrow: "0",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: { xs: 14, md: "20px" },
                      lineHeight: "24px",
                      color: ColorHelper.LightGrey6,
                      flex: "none",
                      order: "0",
                      flexGrow: "0",
                      width: "auto",
                    }}
                  >
                    What Do I get in Ultra Premium Package?
                  </Typography>
                </Box>
                <Box
                  onClick={handleClose}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconHelper.CloseIcon
                    color={ColorHelper.RedColor}
                    bgcolor={ColorHelper.RedColor}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  fontFamily: FontHelper.fRegular,
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#667085",
                  flex: "none",
                  order: "1",
                  alignSelf: "stretch",
                  flexGrow: "0",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                A runner always wonders what s/he gets as a final deliverable,
                before deciding to purchase. Here is the list of various images
                you will be downloading on successful order booking.
              </Typography>
            </Box>

            {/* <Carousel {...carouselParams}> */}

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 18px",
                gap: "30px",
                flex: "none",
                order: "1",
                alignSelf: "stretch",
                flexGrow: "0",
              }}
              // key={index}
            >
              <Grid
                container
                id="ModalContainer"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                  gap: { xs: "20px", md: "60px" },
                  flex: "none",
                  order: "0",
                  flexGrow: "1",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                <Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "0px",
                      gap: "8px",
                      flex: "none",
                      order: { xs: 2, md: 0 },
                      flexGrow: "1",
                    }}
                    style={{ maxWidth: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "4px",
                        flex: "none",
                        order: "0",
                        alignSelf: "stretch",
                        flexGrow: "0",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "24px",
                          color: ColorHelper.LightGrey6,
                          flex: "none",
                          order: "0",
                          flexGrow: "0",
                        }}
                      >
                        Timed Image
                      </Typography>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          color: ColorHelper.LightGrey7,
                          flex: "none",
                          order: "1",
                          alignSelf: "stretch",
                          flexGrow: "0",
                        }}
                      >
                        How about having the run time printed on the image? Your
                        Image with race time may appear like this.
                      </Typography>
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontFamily: FontHelper.fRegular,
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "17px",
                        color: ColorHelper.LightGrey7,
                        flex: "none",
                        order: "1",
                        alignSelf: "stretch",
                        flexGrow: "0",
                      }}
                    >
                      These images will be available Organiser has signed an
                      agreement time printed on the image available with
                      Oneglint at the time Digital downloads.
                    </Typography>
                  </Grid>
                  <Grid>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "4px",
                        flex: "none",
                        order: "0",
                        alignSelf: "stretch",
                        flexGrow: "0",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "24px",
                          color: ColorHelper.LightGrey6,
                          flex: "none",
                          order: "0",
                          flexGrow: "0",
                          marginTop: "25px",
                        }}
                      >
                        Certificate Image
                      </Typography>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          color: ColorHelper.LightGrey7,
                          flex: "none",
                          order: "1",
                          alignSelf: "stretch",
                          flexGrow: "0",
                        }}
                      >
                        Isn't it always exciting to have your photo on
                        Participation Certificate? The participation
                        certification with your photo (in action) will look like
                        this and you get all selected photos in this format.
                      </Typography>
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontFamily: FontHelper.fRegular,
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "17px",
                        color: ColorHelper.LightGrey7,
                        flex: "none",
                        order: "1",
                        alignSelf: "stretch",
                        flexGrow: "0",
                      }}
                    >
                      The availability of Certificate images dependent on the
                      run Organiser and Oneglint's agreement.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UltraPremiumContent />
                </Grid>
              </Grid>
            </Box>
            {/* </Carousel> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UltraPremiumPackageModal;
