import { EVENT, USER } from "./ENDPoint";
import axios from "axios";

//Local  ******------Also change in .env file------******
// const FURL = "http://localhost:4000";
// const CLIENT_URL = "https://test1.photos.oneglint.com/";
// const BASE_URL = `https://test.photos.oneglint.com/`;
// const BASE_API_URL = `https://test.photos.oneglint.com/api/`;


//Live   ******------Also change in .env file------******
const FURL = "https://photos.oneglint.com";
// const CLIENT_URL = "https://sports1.oneglint.com/"; //staging url
const CLIENT_URL = "https://photos.oneglint.com/"; //production url
const BASE_URL = `https://sports.oneglint.com/`;
const BASE_API_URL = `https://sports.oneglint.com/api/`;

;
// const FURL = process.env.REACT_APP_FURL;
// const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default class ApiManager {
  static url() {
    return BASE_URL;
  }

  static fURL() {
    return FURL;
  }

  static homePage() {
    return BASE_API_URL + EVENT.HOME_PAGE;
  }

  static preOrder() {
    return BASE_API_URL + EVENT.PRE_ORDER;
  }

  static generatePreorderLink() {
    return BASE_API_URL + EVENT.GENERATE_PREORDER_LINK;
  }

  static getEventName() {
    return BASE_API_URL + EVENT.GET_EVENT_NAME;
  }

  static getMultiEventList() {
    return BASE_API_URL + EVENT.GET_MULTI_EVENT_LIST;
  }

  static cartDetails() {
    return BASE_API_URL + EVENT.CART_DETAILS;
  }

  static createOrder() {
    return BASE_API_URL + EVENT.CREATE_ORDER;
  }

  static orderSuccess() {
    return BASE_API_URL + EVENT.ORDER_SUCCESS;
  }

  static orderDetail() {
    return BASE_API_URL + EVENT.ORDER_DETAIL;
  }

  static orderHistory() {
    return BASE_API_URL + EVENT.ORDER_HISTORY;
  }

  static downloadOrderZip() {
    return BASE_API_URL + EVENT.DOWNLOAD_ORDER_ZIP;
  }

  static recordFeedbackOnImage() {
    return BASE_API_URL + EVENT.RECORD_FEEDBACK_ON_IMAGE;
  }
  static generateOtp(){
    return BASE_API_URL+EVENT.GENERATE_OTP;
  }
  static getEventDetails(){
    return BASE_API_URL+EVENT.GET_EVENT_DETAILS;
  }

  static getEventThemeDetails() {
    return BASE_API_URL + EVENT.GET_EVENT_THEME_DETAILS;
  }

  static searchBySelfie() {
    return BASE_API_URL + EVENT.SEARCH_BY_SELFIE;
  }

  static searchUnlabledEvent() {
    return BASE_API_URL + EVENT.SEARCH_UNLABELED_EVENT;
  }

  static searchEventByBib() {
    return BASE_API_URL + EVENT.SEARCH_EVENT_BY_BIB;
  }

  static unlabledCartDetails() {
    return BASE_API_URL + EVENT.UNLABELED_CART_DETAILS;
  }
  static masterConfiguration() {
    return BASE_API_URL + EVENT.MASTER_CONFIGURATION;
  }
  static verifyOtp(){
    return BASE_API_URL + EVENT.VERIFY_OTP;
  }

  static login() {
    return BASE_API_URL + USER.LOGIN;
  }

  static socialLogin() {
    return BASE_API_URL + USER.SOCIAL_LOGIN;
  }

  static userRegistration() {
    return BASE_API_URL + USER.USER_REGISTRATION;
  }

  static changePassword() {
    return BASE_API_URL + USER.CHANGE_PASSW0RD;
  }

  static getPasswordCode() {
    return BASE_API_URL + USER.GET_PASSWORD_CODE;
  }

  static passwordReset() {
    return BASE_API_URL + USER.PASSWORD_RESET;
  }

  static checkoutUserInfo() {
    return BASE_API_URL + USER.CHECKOUT_USER_INFO;
  }

  static contactFormSubmit() {
    return BASE_API_URL + USER.CONTACT_FORM_SUBMIT;
  }
  static myOrder() {
    return BASE_API_URL + EVENT.MY_ORDER;
  }
  static preAuthDownload() {
    return BASE_API_URL + EVENT.PRE_AUTH_DOWNLOAD;
  }

  static downloadImage() {
    return BASE_API_URL + EVENT.DOWNLOAD_IMAGE;
  }
  static orgnDashboard() {
    return BASE_API_URL + EVENT.ORGN_DASHBOARD;
  }

  static registerPartner() {
    return BASE_API_URL + EVENT.REGISTER_PARTNER;
  }

  static partnerShare() {
    return BASE_API_URL + EVENT.PARTNER_SHARE;
  }

  static unlabledCart() {
    return CLIENT_URL + EVENT.UNLABELED_CART;
  }

  static cart() {
    return CLIENT_URL + EVENT.CART;
  }
  static getUserCountryBasisOfIP() {
		return axios({
			method: 'GET',
			url: 'https://ipapi.co/json/',
			data: null,
		});
	}
}
