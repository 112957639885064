import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Typography,
} from "@mui/material";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import React, { useEffect, useState } from "react";
import ImageHelper from "../../../Helpers/ImageHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import {
  removeFromCart,
  removePrint,
  removeFromCartCollage,
  removeFromCartDigital,
  addToCart,
  removeCartDownload,
  removePrintModal,
  getCartDetails,
  sponsorDiscount,
} from "../../../redux/shoppingActions";
import axios from "axios";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Pause, PlayCircle } from "@mui/icons-material";
import { isEmpty } from "lodash";
import AddToCartAddedBtn from "../../PorductCard/ProductCardComps/AddToCartAddedBtn";
import { imageDataURI } from "image-data-uri";
import { ApiManager } from "../../../ApiClient";
import * as actionTypes from "../../../redux/shoppingTypes";

export default function CardCart({
  actionStyle,
  item,
  print,
  handleDelete,
  type,
  video,
  digital,
  bibNumber,
  orderId,
  sponsorLogo,
  key
}) {
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const stateDeatils = useSelector((state) => state.shop.products);
  const cartDetails = useSelector((state) => state.shop?.cartDetails);

  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const { appliedDiscount, discountedPhoto } = useSelector(
    (state) => state.shop.sponsorDiscount
  );
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");
  let isPending = localStorage?.getItem("isPending");
  const [creatingURI, setCreatingURI] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckBox = (id) => {
    if (discountedPhoto.includes(id)) {
      dispatch(
        sponsorDiscount({ apply: true, photoID: id }, discountedPhoto, "remove")
      );
    } else {
      dispatch(
        sponsorDiscount({ apply: true, photoID: id }, discountedPhoto, "add")
      );
    }
  };

  const CardBtnStyle = {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.WhiteColor,
    backgroundColor: " #3f0e77",
    px: { xs: "0px", md: "15px" },
    py: { xs: "0px", md: "10px" },
    height: { xs: "40px", md: "auto" },
    borderRadius: 0,
    textTransform: "capitalize",
    width: "100%",
    minWidth: "auto",
    mb: 0,
    display: "flex",
    justifycontent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "&:hover": {
      backgroundColor: "#22c55e",
      borderRadius: "3px",
    },
    "> span": {
      mx: { xs: 0, lg: 0.3 },
    },
  };

  async function downloadFile(data) {
    setIsLoading(true);

    const response = await axios.post(ApiManager.downloadImage(), {
      orderID: orderId,
      bibNumber: bibNumber,
      imageID: data.imageid,
    });
    if (response.data.StatusCode === 200) {
      setIsLoading(false);
      window.location.href = response.data.result
      // console.log(response.data.result)
      // window.open(response.data.result, "_blank");
    } else {
      setIsLoading(false);
    }

    // let fileName = data.imageid;
    // if (response.data.StatusCode === 200) {
    //   if (!response.data.result) {
    //     throw new Error("Resource URL not provided! You need to provide one");
    //   }
    //   setFetching(true);
    //   fetch(response.data.result)
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       setFetching(false);
    //       const blobURL = URL.createObjectURL(blob);
    //       const a = document.createElement("a");
    //       a.href = blobURL;
    //       a.style = "display: none";

    //       if (fileName && fileName.length) a.download = fileName;
    //       document.body.appendChild(a);
    //       a.click();
    //     })
    //     .catch(() => setError(true));
    // }
  }

  const handleDownloadClick = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setImageData(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  // function downloadImage(imageUrl, filename) {

  //   const anchor = document.createElement("a");
  //   anchor.href = imageUrl;
  //   anchor.download = filename;
  //   anchor.style.display = "none";
  //   document.body.appendChild(anchor);
  //   anchor.click();
  //   document.body.removeChild(anchor);
  // }

  const [play, setPlay] = useState(false);
  const path = useLocation();
  const vidRef = React.useRef(null);
  const dispatch = useDispatch();
  const handlePlayVideo = () => {
    vidRef.current?.play();
  };
  const handlePuaseVideo = () => {
    vidRef.current?.pause();
  };

  const handleAddPrint = (item) => {
    const cartItems = {
      id: item?.imageid,
      s3: item?.s3,
      image: item?.product,
      imageAthlete: item?.s3,
      category: "image", //print
      bibNumber: stateDeatils.bibNumber,
      type: "print", //image
      videopath: "",
      // loc:0
    };
    if (AddToCartText == "Add To Cart") {
      setAddtoCartText("Added");
      dispatch(
        addToCart(cartDetails, {
          ...cartItems,
          price: item?.price,
          product: item?.productName,
        })
      );
    } else {
      setAddtoCartText("Add To Cart");
      dispatch(removePrintModal(item?.imageid, cartInfo, item?.productName));
    }
  };
  const handleAddtoCart = (id, s3, image, product, type, price) => {
    const cartItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDeatils.bibNumber,
      type: type,
      videopath: "",
      status: "Added",
      // loc:0
    };

    if (AddToCartText == "Add To Cart") {
      setAddtoCartText("Added");
      product == undefined && price == undefined
        ? dispatch(addToCart(cartDetails, cartItems))
        : dispatch(
          addToCart(cartDetails, {
            ...cartItems,
            product: product,
            price: price,
          })
        );
    } else {
      setAddtoCartText("Add To Cart");
      dispatch(removeCartDownload(id, cartInfo, "download"));
    }
  };

  return (
    <Box key={key}>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        {/* for 3 diffrent conditions we have used same code  repeatedly which is not ideal */}
        {/* the code below is work around and contains redundant code  needs to be changed while doing optimization */}
        {digital !== "digital" && item?.category !== "image" && (
          <Card
            sx={{
              width: "100%",
              p: "20px",
              pb: "5px",
              //   m: 2,
              borderRadius: "5px 5px 0 0",
              border: `1px solid ${ColorHelper.LightGrey4}`,
              position: "relative",
              overflow: "visible",
              alignSelf: "flex-start",
              ...(actionStyle == 2 && {
                pb: 0,
              }),
            }}
            key={item.id}
          >
            <CardMedia
              component={video === "video" ? "video" : "img"}
              alt="Please refresh the page or remove this image"
              height="auto"
              // autoPlay
              ref={video === "video" ? vidRef : null}
              sx={{
                borderRadius: "3px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={
                print == "print"
                  ? item.image
                  : video == "video"
                    ? item.videopath
                    : item.s3
              }
            />
            {video === "video" && (
              <Box
                sx={{
                  width: "full",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // py: '3px',
                  pt: "2px",
                }}
              >
                {play ? (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePuaseVideo();
                    }}
                  >
                    <Pause htmlColor="orange" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePlayVideo();
                    }}
                  >
                    <PlayCircle htmlColor="green" />
                  </div>
                )}
              </Box>
            )}
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item)
                      : handleDelete(item.id);
                  }}
                  sx={
                    print == "print"
                      ? {
                        position: "absolute",
                        top: 3,
                        left: 3,
                        backgroundColor: ColorHelper.WhiteColor,
                        boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                        borderRadius: "50%",
                        aspectRatio: "1/1",
                        maxWidth: "20px",
                        padding: 0,
                        minWidth: "40px",
                        boxShadow:
                          "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                        "&:hover": {
                          backgroundColor: ColorHelper.RedColor,
                        },
                        "&:hover svg path": {
                          fill: ColorHelper.WhiteColor,
                        },
                      }
                      : {}
                  }
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      aspectRatio: "1/1",
                    }}
                  >
                    <img
                      src={print == "print" ? item.imageAthlete : item.image}
                      alt=""
                    />
                  </div>
                </Button>
              )}

              {actionStyle == 2 && type == "orderDetails" ? (
                <Button
                  onClick={() => {
                    downloadFile(item);
                  }}
                  disableripple={true}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: 0,
                    // marginLeft: "auto",
                    mb: 0,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.PrimaryColor,
                    "&:hover": {
                      backgroundColor: ColorHelper.WhiteColor,
                      color: ColorHelper.PrimaryColor,
                      border: `1px solid ${ColorHelper.PrimaryColor}`,
                    },
                  }}
                >
                  <span
                    style={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 13,
                    }}
                  >
                    {" "}
                    {"Download"}{" "}
                  </span>
                  {/* <IconHelper.RemoveFromCart color={ColorHelper.RedColor} /> */}
                </Button>
              ) : null}
            </CardActions>
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item.product, item.seq)
                      : handleDelete(item.id, undefined, item.seq);
                  }}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    backgroundColor: ColorHelper.WhiteColor,
                    boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                    borderRadius: "50%",
                    aspectRatio: "1/1",
                    maxWidth: "20px",
                    padding: 0,
                    minWidth: "40px",
                    boxShadow:
                      "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                    "&:hover": {
                      backgroundColor: ColorHelper.RedColor,
                    },
                    "&:hover svg path": {
                      fill: ColorHelper.WhiteColor,
                    },
                  }}
                >
                  <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                </Button>
              )}
            </CardActions>
          </Card>
        )}
        {item?.category === "image" &&
          item?.type === "print" &&
          item?.bibNumber !== "unlabeled" && (
            <Card
              sx={{
                width: "100%",
                p: "20px",
                pb: "5px",
                maxWidth: "300px",
                //   m: 2,
                borderRadius: "5px 5px 0 0",
                border: `1px solid ${ColorHelper.LightGrey4}`,
                position: "relative",
                overflow: "visible",
                alignSelf: "flex-start",
                ...(actionStyle == 2 && {
                  pb: 0,
                }),
              }}
              key={item.id}
            >
              <CardMedia
                component={video === "video" ? "video" : "img"}
                alt="Please refresh the page or remove this image"
                height="auto"
                // autoPlay
                ref={video === "video" ? vidRef : null}
                // image={
                //   print == "print"
                //     ? item.image
                //     : video == "video"
                //     ? ImageHelper.Video
                //     : item.s3
                // }
                sx={{
                  borderRadius: "3px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={
                  type != "orderDetails"
                    ? print == "print"
                      ? item.image
                      : video == "video"
                        ? item.videopath
                        : item.s3
                    : item?.product
                }
              />
              {type === "orderDetails" &&
                path?.pathname === "/orderDetails" && (
                  <div>
                    <span
                      style={{
                        color: "gray",
                        textAlign: "center",
                        display: "flex",
                        maxWidth: "200px",
                      }}
                    >
                      {item?.productName}
                    </span>
                  </div>
                )}
              {!isEmpty(item?.product) && path?.pathname === "/cart" && (
                <span
                  style={{
                    color: "gray",
                    textAlign: "center",
                    // maxWidth: '100px'
                  }}
                >
                  {item?.product}
                </span>
              )}
              {type === "orderDetails" && (
                <CardActions sx={{ px: 0 }}>
                  <Button
                    disableripple={true}
                    sx={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      backgroundColor: ColorHelper.WhiteColor,
                      boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      maxWidth: "20px",
                      padding: 0,
                      minWidth: "60px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        aspectRatio: "1/1",
                      }}
                    >
                      <img src={item?.s3} alt="" />
                    </div>
                  </Button>
                </CardActions>
              )}
              {video === "video" && (
                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // py: '3px',
                    pt: "2px",
                  }}
                >
                  {play ? (
                    <div
                      onClick={() => {
                        setPlay(!play);
                        handlePuaseVideo();
                      }}
                    >
                      <Pause htmlColor="orange" />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setPlay(!play);
                        handlePlayVideo();
                      }}
                    >
                      <PlayCircle htmlColor="green" />
                    </div>
                  )}
                </Box>
              )}
              <CardActions sx={{ px: 0 }}>
                {type !== "orderDetails" && (
                  <Button
                    disableripple={true}
                    onClick={() => {
                      print == "print"
                        ? handleDelete(item.id, item)
                        : handleDelete(item.id);
                    }}
                    sx={
                      print == "print"
                        ? {
                          position: "absolute",
                          top: 3,
                          left: 3,
                          backgroundColor: ColorHelper.WhiteColor,
                          boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                          borderRadius: "50%",
                          aspectRatio: "1/1",
                          maxWidth: "20px",
                          padding: 0,
                          minWidth: "40px",
                          boxShadow:
                            "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                          "&:hover": {
                            backgroundColor: ColorHelper.RedColor,
                          },
                          "&:hover svg path": {
                            fill: ColorHelper.WhiteColor,
                          },
                        }
                        : {}
                    }
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        aspectRatio: "1/1",
                      }}
                    >
                      <img
                        src={print == "print" ? item.imageAthlete : item.image}
                        alt=""
                      />
                    </div>
                  </Button>
                )}

                {actionStyle == 2 && type == "orderDetails" ? (
                  <Button
                    onClick={() => {
                      downloadFile(item);
                    }}
                    disableripple={true}
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      px: "15px",
                      py: "10px",
                      borderRadius: 0,
                      // marginLeft: "auto",
                      mb: 0,
                      color: ColorHelper.WhiteColor,
                      backgroundColor: ColorHelper.PrimaryColor,
                      "&:hover": {
                        backgroundColor: ColorHelper.WhiteColor,
                        color: ColorHelper.PrimaryColor,
                        border: `1px solid ${ColorHelper.PrimaryColor}`,
                      },
                    }}
                  >
                    <span
                      style={{
                        fontFamily: FontHelper.fBold,
                        fontSize: 13,
                      }}
                    >
                      {" "}
                      {"Download"}{" "}
                    </span>
                    {/* <IconHelper.RemoveFromCart color={ColorHelper.RedColor} /> */}
                  </Button>
                ) : null}
              </CardActions>
              <CardActions sx={{ px: 0 }}>
                {type !== "orderDetails" && (
                  <Button
                    disableripple={true}
                    onClick={() => {
                      print == "print"
                        ? handleDelete(item.id, item.product, item.seq)
                        : handleDelete(item.id, undefined, item.seq);
                    }}
                    sx={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      backgroundColor: ColorHelper.WhiteColor,
                      boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      maxWidth: "20px",
                      padding: 0,
                      minWidth: "40px",
                      boxShadow:
                        "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                      "&:hover": {
                        backgroundColor: ColorHelper.RedColor,
                      },
                      "&:hover svg path": {
                        fill: ColorHelper.WhiteColor,
                      },
                    }}
                  >
                    <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                  </Button>
                )}
              </CardActions>
            </Card>
          )}
        {/* the code below is work around and contains redundant code  needs to be changed while doing optimization */}
        {digital === "digital" && item?.category === "image" && (
          <Card
            sx={{
              width: "100%",
              p: "20px",
              pb: "5px",
              //   m: 2,
              borderRadius: "5px 5px 0 0",
              border: `1px solid ${ColorHelper.LightGrey4}`,
              position: "relative",
              overflow: "visible",
              alignSelf: "flex-start",
              ...(actionStyle == 2 && {
                pb: 0,
              }),
            }}
            key={item.id}
          >
            {appliedDiscount && (
              <CardActions
                sx={{
                  position: "absolute",
                  top: -5,
                  left: 1,
                }}
              >
                <Checkbox
                  checked={discountedPhoto.includes(item.id)}
                  disabled={
                    discountedPhoto.length >=
                    cartPrice.getSponsorOffersDetails.max_orders_allowed &&
                    !discountedPhoto.includes(item.id)
                  }
                  onChange={() => {
                    handleCheckBox(item.id);
                  }}
                />
              </CardActions>
            )}

            <CardMedia
              component={video === "video" ? "video" : "img"}
              alt="Please refresh the page or remove this image"
              height="auto"
              // autoPlay
              ref={video === "video" ? vidRef : null}
              // image={
              //   print == "print"
              //     ? item.image
              //     : video == "video"
              //     ? ImageHelper.Video
              //     : item.s3
              // }
              sx={{
                borderRadius: "3px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              src={
                print == "print"
                  ? item.image
                  : video == "video"
                    ? item.videopath
                    : item.s3
              }
            />
            {video === "video" && (
              <Box
                sx={{
                  width: "full",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // py: '3px',
                  pt: "2px",
                }}
              >
                {play ? (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePuaseVideo();
                    }}
                  >
                    <Pause htmlColor="orange" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePlayVideo();
                    }}
                  >
                    <PlayCircle htmlColor="green" />
                  </div>
                )}
              </Box>
            )}
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item)
                      : handleDelete(item.id);
                  }}
                  sx={
                    print == "print"
                      ? {
                        position: "absolute",
                        top: 3,
                        left: 3,
                        backgroundColor: ColorHelper.WhiteColor,
                        boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                        borderRadius: "50%",
                        aspectRatio: "1/1",
                        maxWidth: "20px",
                        padding: 0,
                        minWidth: "40px",
                        boxShadow:
                          "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                        "&:hover": {
                          backgroundColor: ColorHelper.RedColor,
                        },
                        "&:hover svg path": {
                          fill: ColorHelper.WhiteColor,
                        },
                      }
                      : {}
                  }
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      aspectRatio: "1/1",
                    }}
                  >
                    <img
                      src={print == "print" ? item.imageAthlete : item.image}
                      alt=""
                    />
                  </div>
                </Button>
              )}

              {actionStyle == 2 && type == "orderDetails" ? (
                <Button
                  onClick={() => {
                    downloadFile(item);
                  }}
                  disableripple={true}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: 0,
                    // marginLeft: "auto",
                    mb: 0,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.PrimaryColor,
                    "&:hover": {
                      backgroundColor: ColorHelper.WhiteColor,
                      color: ColorHelper.PrimaryColor,
                      border: `1px solid ${ColorHelper.PrimaryColor}`,
                    },
                  }}
                >
                  <span
                    style={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 13,
                    }}
                  >
                    {" "}
                    {"Download"}{" "}
                  </span>
                  {/* <IconHelper.RemoveFromCart color={ColorHelper.RedColor} /> */}
                </Button>
              ) : null}
            </CardActions>
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item.product, item.seq)
                      : handleDelete(item.id, undefined, item.seq);
                  }}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    backgroundColor: ColorHelper.WhiteColor,
                    boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                    borderRadius: "50%",
                    aspectRatio: "1/1",
                    maxWidth: "20px",
                    padding: 0,
                    minWidth: "40px",
                    boxShadow:
                      "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                    "&:hover": {
                      backgroundColor: ColorHelper.RedColor,
                    },
                    "&:hover svg path": {
                      fill: ColorHelper.WhiteColor,
                    },
                  }}
                >
                  <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                </Button>
              )}
            </CardActions>
          </Card>
        )}
        {item?.bibNumber === "unlabeled" && (
          <Card
            sx={{
              width: "100%",
              p: "20px",
              pb: "5px",
              //   m: 2,
              borderRadius: "5px 5px 0 0",
              border: `1px solid ${ColorHelper.LightGrey4}`,
              position: "relative",
              overflow: "visible",
              alignSelf: "flex-start",
              ...(actionStyle == 2 && {
                pb: 0,
              }),
            }}
            key={item.id}
          >
            <CardMedia
              component={video === "video" ? "video" : "img"}
              alt="Please refresh the page or remove this image"
              height="auto"
              // autoPlay
              ref={video === "video" ? vidRef : null}
              // image={
              //   print == "print"
              //     ? item.image
              //     : video == "video"
              //     ? ImageHelper.Video
              //     : item.s3
              // }
              sx={{
                borderRadius: "3px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={
                print == "print"
                  ? item.image
                  : video == "video"
                    ? item.videopath
                    : item.s3
              }
            />
            {video === "video" && (
              <Box
                sx={{
                  width: "full",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // py: '3px',
                  pt: "2px",
                }}
              >
                {play ? (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePuaseVideo();
                    }}
                  >
                    <Pause htmlColor="orange" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePlayVideo();
                    }}
                  >
                    <PlayCircle htmlColor="green" />
                  </div>
                )}
              </Box>
            )}
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item)
                      : handleDelete(item.id);
                  }}
                  sx={
                    print == "print"
                      ? {
                        position: "absolute",
                        top: 3,
                        left: 3,
                        backgroundColor: ColorHelper.WhiteColor,
                        boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                        borderRadius: "50%",
                        aspectRatio: "1/1",
                        maxWidth: "20px",
                        padding: 0,
                        minWidth: "40px",
                        boxShadow:
                          "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                        "&:hover": {
                          backgroundColor: ColorHelper.RedColor,
                        },
                        "&:hover svg path": {
                          fill: ColorHelper.WhiteColor,
                        },
                      }
                      : {}
                  }
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      aspectRatio: "1/1",
                    }}
                  >
                    <img
                      src={print == "print" ? item.imageAthlete : item.image}
                      alt=""
                    />
                  </div>
                </Button>
              )}

              {actionStyle == 2 && type == "orderDetails" ? (
                <Button
                  onClick={() => {
                    downloadFile(item);
                  }}
                  disableripple={true}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: 0,
                    // marginLeft: "auto",
                    mb: 0,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.PrimaryColor,
                    "&:hover": {
                      backgroundColor: ColorHelper.WhiteColor,
                      color: ColorHelper.PrimaryColor,
                      border: `1px solid ${ColorHelper.PrimaryColor}`,
                    },
                  }}
                >
                  <span
                    style={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 13,
                    }}
                  >
                    {" "}
                    {"Download"}{" "}
                  </span>
                  {/* <IconHelper.RemoveFromCart color={ColorHelper.RedColor} /> */}
                </Button>
              ) : null}
            </CardActions>
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item.product, item.seq)
                      : handleDelete(item.id, undefined, item.seq);
                  }}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    backgroundColor: ColorHelper.WhiteColor,
                    boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                    borderRadius: "50%",
                    aspectRatio: "1/1",
                    maxWidth: "20px",
                    padding: 0,
                    minWidth: "40px",
                    boxShadow:
                      "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                    "&:hover": {
                      backgroundColor: ColorHelper.RedColor,
                    },
                    "&:hover svg path": {
                      fill: ColorHelper.WhiteColor,
                    },
                  }}
                >
                  <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                </Button>
              )}
            </CardActions>
          </Card>
        )}
        {!(digital !== "digital" && item?.category !== "image") &&
          !(digital === "digital" && item?.category === "image") &&
          digital === "digital" &&
          item?.category !== "video" && (
            <Card
              sx={{
                width: "100%",
                p: "20px",
                pb: "5px",
                //   m: 2,
                borderRadius: "5px 5px 0 0",
                border: `1px solid ${ColorHelper.LightGrey4}`,
                position: "relative",
                overflow: "visible",
                alignSelf: "flex-start",
                ...(actionStyle == 2 && {
                  pb: 0,
                }),
              }}
              key={item.id}
            >
              <CardMedia
                component={video === "video" ? "video" : "img"}
                alt="Please refresh the page or remove this image"
                height="auto"
                // autoPlay
                ref={video === "video" ? vidRef : null}
                // image={
                //   print == "print"
                //     ? item.image
                //     : video == "video"
                //     ? ImageHelper.Video
                //     : item.s3
                // }
                sx={{
                  borderRadius: "3px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={
                  print == "print"
                    ? item.image
                    : video == "video"
                      ? item.videopath
                      : item.s3
                }
              />
              {video === "video" && (
                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // py: '3px',
                    pt: "2px",
                  }}
                >
                  {play ? (
                    <div
                      onClick={() => {
                        setPlay(!play);
                        handlePuaseVideo();
                      }}
                    >
                      <Pause htmlColor="orange" />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setPlay(!play);
                        handlePlayVideo();
                      }}
                    >
                      <PlayCircle htmlColor="green" />
                    </div>
                  )}
                </Box>
              )}
              <CardActions sx={{ px: 0 }}>
                {type !== "orderDetails" && (
                  <Button
                    disableripple={true}
                    onClick={() => {
                      print == "print"
                        ? handleDelete(item.id, item)
                        : handleDelete(item.id);
                    }}
                    sx={
                      print == "print"
                        ? {
                          position: "absolute",
                          top: 3,
                          left: 3,
                          backgroundColor: ColorHelper.WhiteColor,
                          boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                          borderRadius: "50%",
                          aspectRatio: "1/1",
                          maxWidth: "20px",
                          padding: 0,
                          minWidth: "40px",
                          boxShadow:
                            "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                          "&:hover": {
                            backgroundColor: ColorHelper.RedColor,
                          },
                          "&:hover svg path": {
                            fill: ColorHelper.WhiteColor,
                          },
                        }
                        : {}
                    }
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        aspectRatio: "1/1",
                      }}
                    >
                      <img
                        src={print == "print" ? item.imageAthlete : item.image}
                        alt=""
                      />
                    </div>
                  </Button>
                )}

                {actionStyle == 2 && type == "orderDetails" ? (
                  <Button
                    onClick={() => {
                      downloadFile(item);
                    }}
                    disableripple={true}
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      px: "15px",
                      py: "10px",
                      borderRadius: 0,
                      // marginLeft: "auto",
                      mb: 0,
                      color: ColorHelper.WhiteColor,
                      backgroundColor: ColorHelper.PrimaryColor,
                      "&:hover": {
                        backgroundColor: ColorHelper.WhiteColor,
                        color: ColorHelper.PrimaryColor,
                        border: `1px solid ${ColorHelper.PrimaryColor}`,
                      },
                    }}
                  >
                    <span
                      style={{
                        fontFamily: FontHelper.fBold,
                        fontSize: 13,
                      }}
                    >
                      {" "}
                      {"Download"}{" "}
                    </span>
                    {/* <IconHelper.RemoveFromCart color={ColorHelper.RedColor} /> */}
                  </Button>
                ) : null}
              </CardActions>
              <CardActions sx={{ px: 0 }}>
                {type !== "orderDetails" && (
                  <Button
                    disableripple={true}
                    onClick={() => {
                      print == "print"
                        ? handleDelete(item.id, item.product, item.seq)
                        : handleDelete(item.id, undefined, item.seq);
                    }}
                    sx={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      backgroundColor: ColorHelper.WhiteColor,
                      boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      maxWidth: "20px",
                      padding: 0,
                      minWidth: "40px",
                      boxShadow:
                        "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                      "&:hover": {
                        backgroundColor: ColorHelper.RedColor,
                      },
                      "&:hover svg path": {
                        fill: ColorHelper.WhiteColor,
                      },
                    }}
                  >
                    <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                  </Button>
                )}
              </CardActions>
            </Card>
          )}
        {type === "orderDetails" && item?.type !== "print" && (
          <Card
            sx={{
              width: "100%",
              p: "20px",
              pb: "5px",
              //   m: 2,
              borderRadius: "5px 5px 0 0",
              border: `1px solid ${ColorHelper.LightGrey4}`,
              position: "relative",
              overflow: "visible",
              alignSelf: "flex-start",
              ...(actionStyle == 2 && {
                pb: 0,
              }),
            }}
            key={item.id}
          >
            <CardMedia
              component={video === "video" ? "video" : "img"}
              alt="Please refresh the page or remove this image"
              height="auto"
              // autoPlay
              ref={video === "video" ? vidRef : null}
              // image={
              //   print == "print"
              //     ? item.image
              //     : video == "video"
              //     ? ImageHelper.Video
              //     : item.s3
              // }
              sx={{
                borderRadius: "3px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={
                print == "print"
                  ? item.image
                  : video == "video"
                    ? item.videopath
                    : item.s3
              }
            />
            {video === "video" && (
              <Box
                sx={{
                  width: "full",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // py: '3px',
                  pt: "2px",
                }}
              >
                {play ? (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePuaseVideo();
                    }}
                  >
                    <Pause htmlColor="orange" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setPlay(!play);
                      handlePlayVideo();
                    }}
                  >
                    <PlayCircle htmlColor="green" />
                  </div>
                )}
              </Box>
            )}
            <CardActions sx={{ px: 0 }}>
              {type !== "orderDetails" && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    print == "print"
                      ? handleDelete(item.id, item)
                      : handleDelete(item.id);
                  }}
                  sx={
                    print == "print"
                      ? {
                        position: "absolute",
                        top: 3,
                        left: 3,
                        backgroundColor: ColorHelper.WhiteColor,
                        boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                        borderRadius: "50%",
                        aspectRatio: "1/1",
                        maxWidth: "20px",
                        padding: 0,
                        minWidth: "40px",
                        boxShadow:
                          "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                        "&:hover": {
                          backgroundColor: ColorHelper.RedColor,
                        },
                        "&:hover svg path": {
                          fill: ColorHelper.WhiteColor,
                        },
                      }
                      : {}
                  }
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      aspectRatio: "1/1",
                    }}
                  >
                    <img
                      src={print == "print" ? item.imageAthlete : item.image}
                      alt=""
                    />
                  </div>
                </Button>
              )}

              {actionStyle == 2 && type == "orderDetails" ? (
                <Button
                  disabled={fetching}
                  onClick={() => {
                    downloadFile(item);
                  }}
                  disableripple={true}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 0,
                    mb: 0,

                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.PrimaryColor,
                    "&:hover": {
                      backgroundColor: ColorHelper.WhiteColor,
                      color: ColorHelper.PrimaryColor,
                      border: `1px solid ${ColorHelper.PrimaryColor}`,
                    }
                  }}
                >
                  <span
                    style={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 13,
                    }}
                  >
                    {" "}
                    {"Download"}{" "}
                  </span>
                </Button>
              ) : null}

              {type !== "orderDetails" ? (
                <AddToCartAddedBtn
                  AddToCartText={AddToCartText}
                  CardBtnStyle={CardBtnStyle}
                  handleCartBtn={() =>
                    handleAddtoCart(
                      item.imageid,
                      item.s3,
                      item.image,
                      undefined,
                      "download",
                      undefined
                    )
                  }
                />
              ) : null}
            </CardActions>
            <CardActions sx={{ px: 0 }}>
              {type ===  "orderDetails" && item?.sponsororderflag == true && sponsorLogo && (
                <Tooltip title="Sponsor Discount Offer" TransitionComponent={Zoom}>
                  <Button
                    disableripple={true}
                    sx={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      backgroundColor: ColorHelper.WhiteColor,
                      boder: `1ps solid ${ColorHelper.WhiteColor3}`,
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      maxWidth: "20px",
                      padding: "2px",
                      minWidth: "40px",
                      boxShadow:
                        "0px -2px 16px 0px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                    }}

                  >
                    <img
                      src={sponsorLogo}
                      style={{ objectFit: "contain", borderRadius: "20px" }}
                      alt="Event Logo"
                    />
                  </Button>
                </Tooltip>
              )}
            </CardActions>
          </Card>
        )}
      </>
    </Box>
  );
}
